export const GET_HOUSE = "GET_HOUSE";
export const ADD_HOUSE = "ADD_HOUSE";
export const HOUSE_LOADING = "HOUSE_LOADING";
export const HOUSE_ADDING = "HOUSE_ADDING";
export const HOUSE_JOINING = "HOUSE_JOINING";
export const HOUSE_JOINED = "HOUSE_JOINED";
export const HOUSE_JOINING_FAILED = "HOUSE_JOINING_FAILED";
export const BID_SUBMITING = "BID_SUBMITING";
export const BID_SUBMITED = "BID_SUBMITED";
export const BID_SUBMITED_FAILED = "BID_SUBMITED_FAILED";
export const PAYMENT_INTIALIZE = "PAYMENT_INTIALIZE";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const PAYMENT_DONE = "PAYMENT_DONE";
export const UPDATE_JOINED_HOUSES = "UPDATE_JOINED_HOUSES";
export const NOTIFICATION_CLICK = "NOTIFICATION_CLICK";
export const NOTIFICATION_LOAD = "NOTIFICATION_LOAD";

export const GET_HOUSE_MEMBER = "GET_HOUSE_MEMBER";
export const MEMBER_LOADING = "MEMBER_LOADING";
export const MEMBER_LOADED = "MEMBER_LOADED";
export const UPDATE_MEMBER_HOUSE = "UPDATE_MEMBER_HOUSE";
export const UPDATE_MEMBER_WALLET = "UPDATE_MEMBER_WALLET";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_LIST = "CLEAR_LIST";

export const ADMIN_LOADING = "ADMIN_LOADING";
export const ADMIN_LOADED = " ADMIN_LOADED ";
export const ADMIN_AUTH_ERROR = "ADMIN_AUTH_ERROR";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT_SUCCESS = "ADMIN_LOGOUT_SUCCESS";
export const ADMIN_REGISTER_SUCCESS = " ADMIN_REGISTER_SUCCESS";
export const ADMIN_REGISTER_FAIL = "ADMIN_REGISTER_FAIL";
export const REQUEST_ALL_MEMBER = "REQUEST_ALL_MEMBER";
export const LOAD_PENDING_TRANSACTION = "LOAD_PENDING_TRANSACTION";

export const ADMIN_LOADING_MEMBER = "ADMIN_LOADING_MEMBER";
export const ADD_MONEY_REQUESTED = "ADD_MONEY_REQUESTED";
export const MONEY_ADDED = "MONEY_ADDED";
export const PAYMENT_APPROVED = "PAYMENT_APPROVED";
export const PAYMENT_DENY = "PAYMENT_DENY";
